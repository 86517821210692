/* 
*******************************************************
*********************Header Styling*********************
******************************************************* */

.app-header {
  background-color: white;
  display: flex;
  border-bottom: 1px solid #edeeee;
}

.app-menu-open {
  width: 4%;
}

.app-logo {
  width: 20%;
}

.app-children {
  min-height: 100vh;
  /* height: 100%; */
  padding-left: 2.625em;
  padding-right: 2.625em;
  padding-top: 1.563em;
  padding-bottom: 5.625em;
  background-color: #edeeee;
}

.logo {
  margin: 1.25em;
  height: 1.5em;
  width: 9.563em;
}

.app-user-info {
  display: flex;
  width: 80%;
  justify-content: flex-end;
}

.app-user-name-block {
  margin-top: 1.25em;
  margin-right: 0.25em;
}

.app-message {
  height: 16px;
  width: 63.14px;
  color: #f15a29;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.app-login-block {
  width: 85%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25em;
  margin-right: 1.25em;
}

.app-message a {
  height: 16px;
  width: 63.14px;
  color: #f15a29;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.app-user {
  height: 16px;
  color: #838486;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
}

.app-initials {
  margin-top: 1em;
  height: 16px;
  width: 24px;
  color: #f15a29;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  justify-content: center;
}

.app-avatar {
  margin-top: 1em;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #ffe6de;
  color: #f15a29;
  margin-right: 0.25em;
  text-align: center;
}

.app-initials {
  margin-top: 12px;
  margin-left: 9px;
}

/* 
*******************************************************
*********************Grid Styling**********************
******************************************************* */

.ui.grid > .row {
  padding-bottom: 0em;
}

.ui.grid > .row > .column {
  padding-left: 0em;
  padding-right: 0em;
}

/* 
*******************************************************
********************Sidebar Styling*********************
******************************************************* */

.pushable > .pusher {
  margin-left: -50px !important;
}

.sticky-sidebar {
  background-color: white;
  padding-left: 1rem !important;
}

/* .ui.visible.left.sidebar~.pusher {
  width: 83.25%!important;
} */

.side-drawer {
  /* margin-top: 6.025em; */
  /* position: fixed; */
}

.ui.left.visible.sidebar {
  background-color: white;
  box-shadow: none;
  border: 0px !important;
  width: 230px !important;
}

.ui.visible.left.sidebar ~ .pusher {
  width: 85%;
}

/* 
*******************************************************
****************Sticky Sidebar Styling******************
******************************************************* */
.drawer-icon {
  font-size: 20px !important;
  margin-top: 1.2em;
  margin-left: 1.5em;
  margin-right: 1em;
  color: #b6b7b8;
  cursor: pointer;
}

.sidebar-icons {
  font-size: 20px !important;
  margin-right: 1em;
  margin-left: 1.5em;
}

.icons-sticky-sidebar {
  margin-top: 6.025em;
  /* position: fixed; */
}

.icons-sticky-sidebar {
  position: fixed;
  top: 2em;
  left: 0;
  height: calc(100vh - 2em);
  width: 80px; /* Adjust based on your sidebar width */
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.icons-sticky-sidebar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.icons-sticky-sidebar:hover {
  overflow-y: auto; /* Show scrollbar when hovering */
}

.tooltip-title {
  font-size: 15px !important;
}
/* 
*******************************************************
*********************Menu Styling**********************
******************************************************* */
/* .menu-items {
  display: flex;
} */

.menu-items {
  display: flex;
  align-items: center;
}

.menu-name {
  margin-top: -1px;
}

.ui.vertical.menu {
  width: auto;
}

.ui.vertical.text.menu .item {
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
}

.expanded > .ui.vertical.text.menu .item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ui.vertical.text.menu .active.item {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  background-color: #edeeee;
  border-left: 3px solid #f15a29;
  padding-top: 12px;
  padding-bottom: 12px;
}

.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  color: #3e3f42;
}

.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover .sidebar-icons {
  color: #3e3f42;
}

.ui.vertical.icon.menu {
  width: -webkit-fill-available;
}

.ui.vertical.menu > .active.item:first-child {
  border-radius: 0px !important;
}

.pageview-chart-name{  
  height: 14px;
  width: 495.78px; 
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 14px;
  margin-top: 20px;
  padding-left: 45px;
  padding-bottom: 30px;
}
.legends {
  display: block;
  margin-right: 75px;
  text-transform: capitalize;
}
  .legend-item {
    padding-top: 3px;
    padding-bottom: 0px;
    font-size: 14px;
    display: inline;
    float: "right" ;
    margin-right: "-55.28rem"
  }
  .pageview-text{
  height: 18px;
  width: 66.23px;
  color: #656669;
  font-family: Roboto;
  letter-spacing: 0;
  line-height: 18px;
  }
.legend-circle {
  height: 15px;
  width: 15px;
  margin-left: 15px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
}
input::-webkit-input-placeholder {
  color: #656669 !important;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
input:focus {
  border-color: red !important;
}

/* ================================ */
.sticky-icons {
  width: 25px !important;
  height: 25px !important;
  margin: 0 20px 0 20px;
  display: block;
  object-fit: contain;
  
}

.sticky-icons-image {
  width: 20px !important;
  height: 20px !important;
  margin: 0 20px 0 20px !important;
  display: block;
  object-fit: contain;
}

.expanded .sticky-icons-image {
  margin: 0 25px 0 30px !important;
}

.login_parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-app-login-block {
  width: 13%;
  padding: 10px;
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-app-login-block button {
  background: linear-gradient(160deg, #f15a29, #e68585);
  border: none;
  color: #fff;
  padding: 12px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 50px;
  width: 14em;
  position: relative;
  overflow: hidden;
  transition: background 0.6s ease, transform 0.6s ease, box-shadow 0.6s ease, filter 0.6s ease, color 0.6s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  filter: brightness(1.1);
}

.new-app-login-block button:hover {
  background: linear-gradient(160deg, #e68585, #f15a29); 
  transform: scale(1.15) rotate(-2deg) translateY(-5px);
  cursor: pointer;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  filter: brightness(1.2); 
  color: #fff;
  animation: pulse 1s infinite, shimmer 4s linear infinite;
}

.new-app-login-block button:active {
  background: linear-gradient(160deg, #e68585, #e68585);
  transform: scale(1.10) translateY(2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  filter: brightness(1.0);
  animation: none;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* spinner */
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #f15a29;
  border-color: #f15a29 transparent #f15a29 transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  */
/*  */
.rounded-loader-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh; /* Full height to center the loader */
  background-color: #f0f0f0; /* Optional background color */
}

.rounded-loader {
  width: 40px;
  height: 40px; 
  border-radius: 50%;
  border: 4px solid #e5582b;
  border-top: 8px solid transparent; 
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start rotation */
  }
  100% {
    transform: rotate(360deg); /* Full rotation */
  }
}

.table-header {
  background-color: #e5582b;
  color: #fff;
  font-weight: bold;
}

.module-row {
  background-color: #f9f9f9;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.module-row:hover {
  background-color: #f1f1f1;
}

.report-row {
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.report-row:hover {
  background-color: #e0e0e0;
}

.element-row {
  background-color: #ffffff;
}

.action-container {
  margin: 5px 0;
}

.action-checkbox .label {
  font-weight: normal;
}

.expandable-content {
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  max-height: 0;
  opacity: 0;
}

.expandable-content.expanded {
  max-height: 1000px;
  opacity: 1;
}

.table-cell {
  padding: 10px;
}


/* Loader overlay to cover the screen */
.main-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it stays above all content */
}

/* Loader animation style */
.main-loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #e5582b; /* Your loader color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
