@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

body {
	background-color: #EDEEEE !important;
}


/* Error Message style */

.ui.negative.message{
	position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 1em !important;
    margin-right: 1em;
    font-size: 16px;
}
.ui.positive.message{
	  position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 1em !important;
    margin-right: 1em;
    font-size: 16px;
}

.error-message-block {
	position: relative;
}

/* .general{
    background-color: #000000;
    color: #ffffff;
    padding: 4px;
   fill: black; 
} */
/* .page-viewtooltip{
 background-color: #000000;
} */

.tooltip-hidden{
    visibility: hidden;
    display: none;
}

.ad-graph {
    background-color: white;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
.topic-chart-name{
    height: 14px;
    width: 495.78px;
    color: #838486;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.13px;
    line-height: 14px;
    padding-left: 40px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
  } 
.legends {
    cursor: pointer;
    display: flex;
    list-style: none;
    text-transform: capitalize;
    margin-right: 75px;
  }
  
.legend-item {
    padding-top: 0px;
    display: inline;
    margin-left: 0px;
    color: #656669;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px
  }
  
.legend-circle {
    height: 10px !important;
    width: 10px !important;
    margin-left: 15px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
  }
.gradient{
    opacity: 0.2;

}
 div.google-visualization-tooltip { 
       border: none;
       background-color: black;
       box-shadow: none; 
       border-radius: 5%;
       font-weight: normal;
       width: auto;
       height: auto;
       padding-left: 15px;
       padding-bottom: 5px;
       padding-top: 5px;
   } 
.pageview-graph  div.google-visualization-tooltip { 
  padding-left: 15px;
  pointer-events: none;
  width: auto;
  height: auto;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 7px;
  /* padding-right: 10px; */
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 18px;
}

#chart-div svg g g g rect + rect{
    fill: none;
    stroke: gray;
    stroke-dasharray: 2, 1;
    stroke-width: 0.5;
    opacity: 0.2;
}
#chart-div-ad svg g g g rect + rect{
  fill: none;
  stroke: gray;
  stroke-dasharray: 2;
  stroke-width: 0.4;
  opacity: 0.2;
  color: transparent;
}
.bar-chart-fill svg g g g rect + rect{
  stroke-dasharray: 3;
  stroke-width: 0.7;
}
.bar-chart-qualification-fill svg g g g rect + rect{
  stroke-dasharray: 3;
  stroke-width: 0.7;
}
text{
    height: 18px;
    width: 66.23px;
    fill: #b6b7b8;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
.topic-text{
    height: 18px;
    width: 66.23px;
    color: #656669;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
.tooltip-pageview{
    color: #ffffff;
    padding: 12px;
    fill: black;
    color: #FFFFFF;
    font-family: Roboto;
}
.tooltip-pageview-values{
    opacity: 0.85;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    fill: black;
    font-family: Roboto;
    width: auto;
    font-size: 12px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.topic-graph div.google-visualization-tooltip { 
  padding-left: 15px;
  pointer-events: none;
  width: auto;
  height: auto;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 10px;
  /* padding-right: 10px; */
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 18px;
}