/* 
*******************************************************
********************Page Styling***********************
******************************************************* */

.page-name-comp {
  margin-bottom: 23px;
}

.page-name {
  height: 10px;
  width: 710px;
  color: #3E3F42;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
}

/* 
*******************************************************
****************Search Filter Styling******************
******************************************************* */

/* elements styling */

.date-picker {
  position: relative;
  display: flex;
}

.custom-date-picker {
  position: relative;
  display: flex;
}

.custom-date-picker .ui.selection.active.dropdown .menu {
  display: none !important;
}

.no-blink {
  caret-color: transparent;
}

.filter-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  padding: 6px 8px;
  border: 1px solid #b2b3b3;
  border-radius: 4px;
}

.filter-icon:hover {
  box-sizing: border-box;
  border: 1px solid #6D6E71;
  border-radius: 5px;
}

.filter-icon-selected {
  cursor: pointer;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  padding: 6px 8px;
  border: 1px solid #f15a29;
  border-radius: 4px;
}

.material-icons.grey {
  font-size: 18px;
  color: #838486;
}

.material-icons.orange {
  font-size: 18px;
  color: #f15a29;
}

/* .material-icons {
	  font-size: 18px;
	  color:  #f15a29;
	} */

.popup-component {
  position: relative;
  display: flex;
  width: 100%;
}

.custom-date-popup-open {
  background-color: white;
  box-shadow: 2px 3px 3px 2px rgba(35, 57, 66, 0.21);
  position: absolute;
  /* display: -webkit-inline-box; */
  z-index: 9999;
}

.custom-date-popup-close {
  display: none;
}

/* .date-picker-close-icon {
  cursor: pointer;
  padding-top: 12px;
  padding-right: 2px;
} */

.date-picker-close-icon {
  cursor: pointer;
  padding-top: 10px;
  padding-right: 10px;
  float: right;
}

.date-buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  display: flex;
  margin-top: 0.625em;
}

.cancel-date-button {
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 260px;
  border: 1px solid #f15a29;
  border-radius: 4px;
  color: #f15a29;
  background-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 1em;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.apply-date-button {
  cursor: pointer;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 260px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1em;
}

.filter-popup-open {
  box-shadow: 2px 3px 3px 2px rgba(35, 57, 66, 0.21);
  position: absolute;
  right: 0;
  background-color: white;
  padding: 12px;
  z-index: 9;
}

.filter-popup-close {
  display: none;
}

.search-filter-form {
  width: 340px;
  padding-left: 10px;
  padding-right: 10px;
}

.filter-header {
  display: inline-block;
  width: 100%;
}

.filter-text {
  float: left;
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 16px;
  padding-left: 10px;
}

.filter-close-icon {
  cursor: pointer;
  float: right;
  padding-right: 10px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 0.625em;
}

.clear-filter-button {
  cursor: pointer;
  border-radius: 5px;
  background-color: #b6b7b8;
  height: 40px;
  width: 150px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
}

.apply-filter-button {
  cursor: pointer;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 152px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.leads-search-filter {
  width: 700px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.engagements-search-filter {
  width: 700px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.search-filter-column-1 {
  padding-right: 0.5em;
  width: 350px;
}

.search-filter-column-2 {
  padding-left: 0.5em;
  width: 350px;
}

.firmographic-toggles {
  margin-bottom: 10px;
}

.toggle-label {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 10px;
}

.ad-toggle-label {
  margin-top: 8px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 10px;
}

.ad-child-toggle {
  margin-left: 25px !important;
  padding-top: 12px;
  padding-right: 2px;
}

.child-toggle {
  margin-left: 25px !important;
}

.inner-child-toggle {
  margin-left: 50px !important;
}

.pg-search-filter {
  width: 700px;
  padding-left: 10px;
  padding-right: 10px;
}

.ad-search-filter {
  width: 700px;
  padding-left: 10px;
  padding-right: 10px;
}

/* semantic ui toggle styling */

.ui.toggle.checkbox input:checked~label {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
}

.ui.toggle.checkbox label {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
}

.ui.checkbox input:focus~label {
  color: #3e3f42;
}

.ui.toggle.checkbox input:checked~label:before {
  background-color: #f15a29 !important;
}

.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #f15a29 !important;
}

.ui.toggle.checkbox label:before {
  background-color: #838486;
  width: 3rem;
  height: 1.525rem;
}

.ui.toggle.checkbox label:hover::before {
  background-color: #838486;
}

.ui.toggle.checkbox input:focus~label:before {
  background-color: #838486;
}

.ui.toggle.checkbox input~label:after {
  box-shadow: 0 1px 2px 0 #838486, 0 0 0 1px #838486 inset;
}

.ui.toggle.checkbox label:after {
  width: 1.25em;
  height: 1.25em;
}

.ui.toggle.checkbox input:checked~label:after {
  width: 1.25em;
  height: 1.25em;
}

.ui.toggle.checkbox input~label:after {
  left: 0.075rem;
  top: 0.115em;
}

.ui.toggle.checkbox input:checked~label:after {
  left: 1.55rem;
}

/* semantic ui dropdown styling */

.ui.selection.active.dropdown {
  box-shadow: rgba(34, 36, 38, 0.15) 0px 2px 3px 0px;
  border-color: #e5582b;
}

.ui.active.selection.dropdown>.dropdown.icon,
.ui.visible.selection.dropdown>.dropdown.icon {
  color: #e5582b;
}

.ui.selection.active.dropdown:hover {
  box-shadow: rgba(34, 36, 38, 0.15) 0px 2px 3px 0px;
  border-color: #e5582b;
}

.ui.dropdown>.dropdown.icon::before {
  font-family: "Icons";
  content: "\f107" !important;
  /* color: #e5582b; */
}

.ui.active.search.dropdown input.search:focus+.text {
  color: #e5582b !important;
}

.ui.active.search.dropdown>.visible.menu.transition {
  max-height: 335px;
}

.ui.dropdown>.clear.icon::before {
  font-family: "Icons";
  content: "\f00d" !important;
}

.ui.dropdown:not(.button)>.default.text {
  color: #656669;
  font-family: Roboto;
  letter-spacing: 0;
  line-height: 16px;
}

.ui.form .field>label {
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 14px;
  height: 16px;
}

.ui.selection.dropdown {
  width: 14.75em;
  /* height: 40px; */
}

.ui.selection.dropdown:hover {
  box-sizing: border-box;
  border: 1px solid #6D6E71;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.ui.selection.dropdown .menu>.item {
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  /* font-weight: normal; */
}

.ui.search.dropdown>.text {
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: none;
  /* font-weight: bold; */
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

/* date range picker styling */

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrMonth {
  padding: 0px;
  width: 23.667em;
}

.rdrMonthName {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  padding: 0em;
}

.rdrDayPassive {
  background-color: transparent;
}

.rdrDayPassive .rdrDayNumber span {
  color: white !important;
}

.rdrDateDisplayWrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  background-color: white;
}

.rdrDateDisplay {
  margin: 5px;
}

.rdrDay {
  height: 25px;
}

.rdrMonthAndYearWrapper {
  height: 35px;
  padding-bottom: 10px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDay {
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.rdrWeekDay {
  display: block;
  color: transparent;
}

.rdrWeekDay::first-letter {
  visibility: visible;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 0px;
  font-weight: bold;
  background: #e5582b;
}

.rdrDateDisplayItemActive {
  padding: 1px;
  color: #e5582b;
}

.rdrSelected {
  padding: 10px;
  color: #e5582b;
}

.rdrDayInPreview {
  padding: 10px;
  color: #e5582b !important;
  margin-top: -3px;
}

/* .rdrDateInput {
    display: none;
  }
  .rdrDateDisplayItem{
    display: none;
  } .rdrDateDisplayItemActive{
    display: none;
  } */
.rdrDayStartPreview {
  padding: 10px;
  color: #e5582b !important;
  margin-top: -3px;
}

.rdrDayEndPreview {
  padding: 10px;
  color: #e5582b !important;
  margin-top: -3px;
}

.rdrStartEdge {
  padding: 10px;
  color: #e5582b;
  margin-top: -3px;
}

.rdrEndEdge {
  padding: 10px;
  color: #e5582b;
  margin-top: -3px;
}

.rdrInRange {
  padding: 10px;
  color: #fbebe5;
  opacity: 0.2;
  margin-top: -3px;
}

.rdrDayToday span:after {
  background: #e5582b !important;
}

.rdrMonthAndYearPickers select {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  padding: 5px 30px 5px 5px;
}

.rdrDateDisplayItem input {
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  padding: 0px;
}

.rdrDateDisplayItemActive {
  color: #e5582b !important;
}

.rdrDayNumber span {
  color: #838486 !important;
  font-weight: 400;
}

.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span {
  color: #ffffff !important;
}

/* 
  *******************************************************
  ***************Sortable Table Styling******************
  ******************************************************* */
.table-heading-block {
  display: inline-block;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
}

.table-name {
  float: left;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
}

.download-button {
  cursor: pointer;
  float: right;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 200px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.sync-button {
  cursor: pointer;
  float: right;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 200px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.show-csv-loader {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

.ui.table {
  border: 0 !important;
}

.ui.table thead {
  background: #edeeee !important;
}

.ui.table tr td {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

tr.rdCamp {
  cursor: pointer;
}

tr.rdCamp-selected {
  cursor: pointer;
  background-color: #fdebe5;
}

tr.ad {
  cursor: pointer;
}

tr.ad-selected {
  cursor: pointer;
  background-color: #fdebe5;
}

tr.ad-children {
  cursor: pointer;
  background-color: rgba(253, 235, 229, 0.35);
}

tr.ad-hide {
  display: none;
}

tr.li-children-selected {
  cursor: pointer;
  background-color: rgba(253, 235, 229, 0.6);
}

tr.li-children {
  cursor: pointer;
  background-color: rgba(253, 235, 229, 0.35);
}

tr.li-children-v2 {
  cursor: pointer;
  background-color: #f9d9ce45;
}

tr.c-children {
  background-color: rgba(253, 235, 229, 0.1);
}

tr.li-hide {
  display: none;
}

tr.c-hide {
  display: none;
}

td.creative-name {
  padding-left: 50px !important;
}

td.ad-order-name {
  padding-left: 50px !important;
}

/* Topic Hub Detail Styling*/

tr.topic-camp-selected {
  cursor: pointer;
  background-color: #fdebe5;
}

tr.topic-camp-selected-v2 {
  cursor: pointer;
  background-color: #f9d9cee3;
}

tr.level-1 {
  cursor: pointer;
  background-color: #f9d9cee3; /* Base color */
}

tr.level-2 {
  cursor: pointer;
  background-color: #fce6df; /* Slightly lighter */
}

tr.level-3 {
  cursor: pointer;
  background-color: #fef2ed; /* Even lighter */
}

tr.level-4 {
  cursor: pointer;
  background-color: #fff9f6; /* Lightest */
}

tr.li-children {
  cursor: pointer;
  background-color: rgba(253, 235, 229, 0.35);
}

tr.li-children2 {
  cursor: pointer;
  background-color: rgba(252, 245, 242, 0.35);
}

tr.title-children-selected {
  cursor: pointer;
  background-color: rgba(253, 235, 229, 0.6);
}

tr.title-children {
  background-color: rgba(253, 235, 229, 0.1);
}

tr.c-title-children {
  background-color: rgba(253, 235, 229, 0.1);
}

tr.title-hide {
  display: none;
}

tr.c-title-hide {
  display: none;
}

.ui.table thead th {
  height: 14px !important;
  width: 12em;
  color: #838486 !important;
  font-size: 12px !important;
  letter-spacing: 1.13px !important;
  line-height: 1.2em;
  background: #edeeee !important;
  text-transform: uppercase;
  font-weight: bold !important;
}

.ui.sortable.table thead th {
  cursor: pointer;
  border-left: 0 !important;
  white-space: normal;
  vertical-align: top;
}

.no-sort {
  cursor: default !important;
}

.ui.sortable.table thead th.sorted:hover {
  background: #edeeee !important;
}

.ui.table tr td {
  border-top: 2px solid rgba(34, 36, 38, 0.1);
}

.table-divider {
  margin-top: 35px;
}

.ui.divider {
  border-top: 1px solid #ffffff !important;
}

.show-more-button {
  cursor: pointer;
  height: 40px;
  width: 130px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  align-items: center;
  position: absolute;
  background-color: #f15a29;
  color: white;
  letter-spacing: 0;
  box-sizing: border-box;
  border: 1px solid #F15A29;
  border-radius: 5px;
}

.show-more-button:hover{
  cursor: pointer;
  border-radius: 5px;
  background-color: #e5582b;
  color: #FFFFFF;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%)
}

.show-more-button-disable{
  height: 40px;
  width: 130px;
  align-items: center;
  position: absolute;
  letter-spacing: 0;
  border-radius: 4px;
  background-color: #D3D4D4;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border: none;
}

.show-more-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-loader {
  margin-top: 10px;
}

.sortable-table-component {
  overflow-x: scroll;
}

.ui.sortable.table thead th.ascending:after {
  content: "\f106" !important;
}

.ui.sortable.table thead th.descending:after {
  content: "\f107 " !important;
}

/* Table style */

td .material-icons {
  cursor: pointer;
  color: #b6b7b8;
  font-size: 16px;
  padding-right: 10px;
}

/*Column Widths */

th.custom-column-large {
  width: 25em !important;
}

th.custom-column-medium {
  width: 14em !important;
}

th.custom-column-clicks {
  width: 22em !important;
}

th.custom-column-verification-state {
  width: 37em !important;
}

th.unique-impressions {
  width: 11em !important;
}

th.custom-column-extra-medium {
  width: 15em !important;
}

th.impressions {
  width: 11em !important;
}

th.clicks {
  width: 7em !important;
}

th.counts {
  width: 11em !important;
}

th.ctr-for-daterange {
  width: 10em !important;
}

th.percentage-served {
  width: 10em !important;
}

th.total-impressions {
  /* width: 14em !important; */
  width: 10em !important;
}

th.toggle-row {
  width: 2.3em !important;
}

th.custom-column-xlarge {
  width: 30em !important;
}

th.custom-column-author {
  width: 18em !important;
}

th.hidden-column-ad-campaign {
  display: none;
}

th.icon {
  width: 5em !important;
}

td.hidden-column-ad-campaign {
  display: none;
}

th.hidden-column-ad-imp {
  display: none;
}

td.hidden-column-ad-imp {
  display: none;
}

/* Message style */

.ui.message {
  background: white;
  position: unset;
  border-radius: 0ex;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* 
  *******************************************************
  *******************Ad Graph Styling********************
  ******************************************************* */

.ad-graph-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  height: 2.7em;
}

@media screen and (min-height: 320px) {
  .ad-graph {
    height: 350;
  }

  .legend-item {
    display: inline;
  }
}

.ad-graph {
  background-color: white;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  height: auto;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow-wrap: break-word;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.domain {
  display: none;
}

.chart-name {
  height: 14px;
  width: 495.78px;
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 14px;
  padding-left: 40px;
  padding-top: 30px;
}

.legends {
  cursor: pointer;
  display: flex;
  list-style: none;
  text-transform: capitalize;
  margin-right: 75px;
  text-transform: capitalize;
}

.legend-item {
  padding-top: 10px;
  display: inline;
  margin-left: 0px;
}

.legend-circle {
  height: 15px;
  width: 15px;
  margin-left: 15px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
}

.tick line {
  stroke: black;
  opacity: 0.2;
  stroke-dasharray: 5, 5;
}

.tick text {
  fill: #B6B7B8;
}

/* 
  *******************************************************
  *******************Topic Graph Styling********************
  ******************************************************* */

.topic-graph-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  height: 2.7em;
}

.zoom-webinar {
  display: none;
}

/* Drawer Styling */

.main-div {
  margin-top: 15px;
}

.drawer-text {
  margin-left: 10px;
  padding-top: 25px;
  margin-bottom: 5px;
  padding-bottom: 8px;
}

.drawer-value {
  padding-top: 10px;
  margin-left: 10px;
  font-family: Roboto;
  margin-top: 10px;
}

.icon-box {
  height: 11.8rem;
  color: red;
  font-family: "Roboto";
  font-weight: 50;
}

.icon-text {
  float: left;
  color: #838486;
  font-family: Roboto;
  font-size: 1.14rem;
  font-weight: 400;
  width: 10rem;
  letter-spacing: 0;
  padding-top: 3px;
  padding-left: 3px;
}

.icon-value {
  padding-left: 0px;
  padding-right: 0px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 2.6rem;
  margin: 10px;
  margin-right: 1px;
  font-weight: 50;
  padding-top: 11px;
  margin-left: 2px;
}

.icons {
  height: 3.92rem;
  width: 3.92rem;
  background: #fdebe5;
  border-radius: 50%;
  line-height: 30px;
  padding: 15px;
  max-width: 4rem;
  max-height: 4rem;
}

.drawer-checkbox {
  font-family: Roboto;
  left: -7rem;
  bottom: 13px;
  padding-top: 40px;
}

.MuiDrawer-paperAnchorRight {
  width: 31rem;
  margin-top: 61px;
  padding-left: 15px;
  padding-bottom: 5rem;
}

.drawer-heading-text {
  padding-top: 15px;
  margin-left: 10px;
  margin-top: 10px;
  padding-left: 10px;
  color: #838486;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  padding-left: 10px;
}

/* pageview graph styling   */
.pageview-graph {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

text {
  height: 18px;
  width: 66.23px;
  fill: #b6b7b8;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.job-Titles {
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 4px;
}

.topic-graph {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.custom-chkbox {
  padding-left: 25px;
  padding-top: 5px;
}

.ui.checkbox input.hidden+label {
  color: #838486;
}

.custom-date {
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ui.dropdown .menu .active.item {
  color: #3e3f42;
  font-family: "Roboto";
  font-weight: 400;
}

#reactgooglegraph-1>div>div:nth-child(1)>div>svg>g:nth-child(3)>g:nth-child(2)>g:nth-child(3)>path:nth-child(2) {
  stroke-dasharray: 2;
}

.ui.card {
  display: block;
  position: absolute;
  align-self: center;
  margin-top: 45px;
  z-index: 99;
}

.header {
  text-align: center;
}

.card-close-icon {
  cursor: pointer;
  float: right;
  /* margin-left: 19em; */
}

.card-popup-close {
  display: none;
}

.class-relative {
  position: relative;
}

.class-relative>div {
  padding-right: 5px;
  display: inline-block;
  float: right;
}

.detail-excel-button {
  padding-left: 5px;
}

.pg>.ui.card:first-child {
  margin-top: 45px;
}

.pg.popup {
  width: 85px;
}

.ui.tabular.menu .item {
  float: left;
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 16px;
  padding-left: 10px;
}

.dropdown header {
  height: 14px !important;
  width: 12em;
  color: #838486 !important;
  font-size: 12px !important;
  letter-spacing: 1.13px !important;
  line-height: 14px !important;
  background: #edeeee !important;
  text-transform: uppercase;
  font-weight: bold !important;
}

th.leadstate {
  min-width: 11em !important;
  max-width: 22em !important;
}

.lead-validation-graph {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.lead-validation-barchart {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.lead-validation-barchart-qualification {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.lead-engs-validation-barchart-qualification {
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.lead-validation-graph div.google-visualization-tooltip {
  width: auto;
  height: auto;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.lead-validation-barchart div.google-visualization-tooltip {
  width: 190px;
  height: auto;
  padding-bottom: 0px;
}

.lead-validation-barchart-qualification div.google-visualization-tooltip {
  width: 200px;
  height: 80px;
}

.ad-menus .ui vertical menu{
  padding-left: 10px;
}

.lead-engs-validation-barchart-qualification div.google-visualization-tooltip {
  width: auto;
  height: auto;
}

.pageview-menus .ui vertical menu {
  padding-left: 10px;
}

.menu-list-items {
  padding-top: 20px;
}

.ad-chart div.google-visualization-tooltip {
  height: auto;
  padding-left: 20px;
  pointer-events: none;
  width: 220px;
}

.ad-chart-name {
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 14px;
  padding-left: 15px;
  padding-top: 24px;
  padding-bottom: 20px;
  right: 0;
  padding-left: 35px
}

.ui.card.maildelivery {
  display: block;
  position: absolute;
  align-self: center;
  /* margin-top: -158.5%; */
  z-index: 999;
  margin-left: 57%;
  margin-top: 190px;
  width: 350px;
  margin-left: 18.3rem;
}

.ad-chart-name .checkbox-label {
  font-weight: 200;
  font-family: Roboto;
  font-size: 16px;
  font-size: 16px;
  font-style: normal;
  color: #3E3F42 !important;
  letter-spacing: 0;
}

.message-chart-text {
  /* font-weight: 200; */
  /* font-family: Roboto;
  letter-spacing: 0.5px;
  font-style: normal;
  height: 16px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: black;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0,0,0,.87);
  border-radius: 0.28571429rem; */
  height: 18px;
  width: 241px;
  color: #3E3F42;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.dynamic_table--list {
  right: 0;
  min-width: 160px;
  z-index: 9;
  color: rgb(131, 132, 134);
  line-height: 25px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 20px;
  width: 300px !important;
  position: absolute;
  margin-top: -35px !important;
  /* margin-right: 37px !important; */
  margin-right: 8px !important;
}

.dynamic_table--list_for_ads {
  right: 0;
  min-width: 160px;
  z-index: 1;
  color: rgb(131, 132, 134);
  line-height: 25px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 20px;
  width: 300px !important;
  position: absolute;
  margin-top: -35px !important;
  /* margin-right: 37px !important; */
  margin-right: 46px !important;
}

.tag_summary {
  margin-right: 45px !important;
  margin-top: -15px !important;
}

.table_header--checkbox {
  height: 24px;
  width: 200px;
  color: #3E3F42;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 3px;
  accent-color: black;
}

.table_header--checkbox input:focus:checked~label:before {
  background-color: #f15A29 !important;
  border: 0px;
}

.table_header--checkbox input:checked~label:before {
  background-color: #f15a29 !important;
  border: 0px;
}

.table_header--checkbox input:checked~label:after {
  opacity: 1;
  color: white !important;
}

.sorted_icon {
  float: right;
  cursor: pointer;
  margin-bottom: -50px !important;
}

.sorted_icon_for_ads {
  float: right;
  cursor: pointer;
  margin-bottom: -50px !important;
}

.apply_menu--btn {
  padding: 7px;
  margin-top: 20px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f15a29;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
}

.sort__header-table-list {
  pointer-events: none;
  opacity: 0.4;
}

#lead_summary_detail {
  margin-top: 60px;
}

.filters-checkbox {
  padding-left: 10px;
  padding-top: 10px;
}

.filter_date {
  width: 23.5em !important;
}

.menus {
  position: "absolute";
  z-index: "90";
  color: "#838486";
  font-family: "Roboto";
  font-size: "16px";
  letter-spacing: 0;
  line-height: "16px";
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px,
}

/* .menus .pageview-menu{
  margin-top: "290px"
}   */
.button_color_green {
  cursor: pointer;
  float: right;
  border-radius: 7px;
  background-color: rgb(51, 172, 46);
  height: 25px;
  width: 40px;
  border: 0px;
}

.button_color_grey {
  cursor: pointer;
  float: right;
  border-radius: 7px;
  background-color: rgb(116, 116, 116);
  height: 25px;
  width: 40px;
  border: 0px;
}

.button_color_yellow {
  cursor: pointer;
  float: right;
  border-radius: 7px;
  background-color: rgb(252, 175, 23);
  height: 25px;
  width: 40px;
  border: 0px;
}

.button_color_red {
  cursor: pointer;
  float: right;
  border-radius: 7px;
  background-color: rgb(214, 54, 73);
  height: 25px;
  width: 40px;
  border: 0px;
}

.label_publish_data {
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 16px;
}

.total_lead_count {
  margin-top: 40px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 1000;
  color: #838486;
}

.apply-filter-button-lead-table {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  height: 40px;
  width: 152px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.button-text {
  color: #e5582b;
}

.total_lead_count {
  margin-top: 0px;
  padding-left: 13rem;
  padding-right: 20rem;
  font-size: 16px;
  font-weight: 1000;
  color: #838486;
}
.engs-barchart{
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  height: 3em;
}

.apply_basic_button {
  margin-left: 30px;
  margin-top: 27px;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 160px;
  border: 1px solid #F15A29;
  border-radius: 5px;
  color: #F15A29;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  background-color: white;
}

.cancel-date-button:hover {
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 260px;
  border: 1px solid #D43C21;
  border-radius: 5px;
  color: #D43C21;
  background-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 1em;
}

.apply_basic_button:hover {
  margin-left: 30px;
  margin-top: 27px;
  cursor: pointer;
  height: 40px;
  width: 160px;
  color: #D43C21;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #D43C21;
  border-radius: 4px;
}

/* 
.active-state-button{
  margin-left: 30px; 
  margin-top: 27px;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 160px;
  border: 1px solid #F15A29;
  border-radius: 4px;
  background-color: #FDEBE5;
  color: #D43C21;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
} */

.apply-date-button:hover {
  cursor: pointer;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 260px;
  color: FFFFFF;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1em;
  letter-spacing: 0;
  text-align: center;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%)
}

.apply-filter-button:hover {
  letter-spacing: 0;
  text-align: center;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%);
}

.apply_menu--btn:hover {
  letter-spacing: 0;
  text-align: center;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%);
}

.download-button:hover {
  cursor: pointer;
  float: right;
  border-radius: 5px;
  background-color: #e5582b;
  height: 40px;
  width: 200px;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%)
}

.line-vertical {
  border-left: 3px solid #B6B7B8;
  height: auto;
  position: absolute;
  left: 2%;
  margin-left: -3px;
  margin-top: 90px;
}

.expanded > .line-vertical {
  height: 290px;
}

.expanded .menu-name {
  margin-bottom: 0px;
}

.line-vertical-pageview {
  border-left: 3px solid #B6B7B8;
  height: 250px;
  position: absolute;
  left: 2%;
  margin-left: -3px;
  margin-top: 90px;
}

#sticky-sidebar-width {
  width: 15.4% !important;
  height: 100%;
  overflow-y: auto;
  position: sticky;
  top: 0;
  padding-top: 3em;
}

#main-content-width {
  width: 84.5% !important;
  height: 100%;
  overflow-y: auto;
  background: #fff;
}

.extra-space {
  border-radius: 5px !important;
  height: 40px;
  width: 150px !important;
  margin-right: 16px;
  margin-top: 1px;
}

.extra-space:hover {
  margin-top: 1px;
}

.sync-button:hover {
  letter-spacing: 0;
  text-align: center;
  background: linear-gradient(180deg, #F58C69 0%, #F15A29 100%);
}

#chart-div-ad div.google-visualization-tooltip {
  height: auto;
  padding-left: 20px;
  pointer-events: none;
  width: 220px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .ui.input:hover {
  border: 0.1px solid #6D6E71;
  border-radius: 5px;
  height: 37px;
  font-size: 1em;
  position: relative;
  width: 337px !important;
} */

.input:hover {
  border: 0.1px solid #6D6E71;
  border-radius: 5px;
  height: 37px;
  font-size: 1em;
  position: relative;
  width: 337px !important;
}

.ui.message {
  color: #3E3F42;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.blogs_link {
  height: 24px;
  width: 321px;
  color: #005AA0 !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.blogs_link:hover {
  color: #0077B8 !important;
  text-decoration: underline !important;
}

.blogs_link:active {
  color: #0077B8 !important;
}

.label_text {
  height: 14px;
  color: #838486;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 14px;
}

#chart-div-ad div.google-visualization-tooltip {
  padding-left: 20px;
  pointer-events: none;
  padding-top: 0px !important;
  width: auto;
  height: auto !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.engs-barchart div.google-visualization-tooltip {
  width: 200px;
  height: auto;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.lead-engs-validation-barchart-qualification div.google-visualization-tooltip {
  width: auto;
  height: auto;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.lead-validation-barchart-qualification div.google-visualization-tooltip {
  width: auto;
  height: auto;
  padding-bottom: 1.5px !important;
  padding-top: 0px;
}

.space_height {
  height: 48px;
}

.text_right_align {
  text-align: right !important;
  width: 100px;
}

#space_height-row{
  height: 48px;
}

.ad-chart{
  padding-bottom: 41.3px;
}

#charts-gap{
  margin-top: 30px;
} 
.independant-event-filters{
  padding-top: 0px;
}

.download-chart-icon{
  cursor: pointer;
  float: right;
  margin-top: 10px;
  font-size: 24px;
  margin-right: 30px;
  right: 0;
  box-sizing: border-box;
  padding: 6px 8px;
  border: 1px solid #b2b3b3;
  border-radius: 4px;
}

.download-chart-icon:hover{
  color: #D43C21 !important;
  border: 1px solid #D43C21 !important;
}

.html2canvas-container { height: 300rem !important; }
.previous-column-classname{
  border-bottom: none; 
  padding-right:40px
}

th.custom-column-extra-large {
  width: 30em !important;
}

.sticky-table-container {
  max-height: 1200px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.sticky-table-container thead tr {
  position: sticky;
  top: 0;
  z-index: 3;
}

.sticky-table-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.utm-params_style {
  margin-bottom: 27px !important;
  margin-top: 44px !important;
  padding-left: 5px !important;
}

.margin-from-top {
  margin-top: 16px;
}

.profile_hovor{
  cursor: pointer;
}

.arrow_down {
  padding-top: 20px;
  color: #8A8A8A;
  padding-right: 30px !important;
}

.ui.vertical.menu {
  position: absolute;
  height: auto;
  z-index: 9;
  margin-top: 62px;
}

.expanded  .ui.vertical.menu {
  position: relative;
  margin-top: 0px;
}

.ui.vertical.menu .item {
  cursor: pointer;
  color: rgb(131, 132, 134) !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  line-height: 1 !important;
	font-family: Roboto !important;
	font-size: 16px !important;
}

.extra-space_filter_item {
  margin-bottom: 86px;
}

.dropdown-row {
  display: flex;
  gap: 10px;
}

.extra_space_for_button {
  margin-bottom: 10px;
}

.input_form-control {
  width: 150px;
  height: 38px;
  color: #838486;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.13px;
  line-height: 4px;
}

.input_form-control:not(:focus):hover,
.input_form-control:focus,
.input_form-control:focus-visible {
  box-sizing: border-box;
  border: 1px solid #D43C21 !important;
  border-radius: 5px;
  background-color: transparent;
}

.filter-buttons_actions {
  display: flex;
  justify-content: center;
  margin-bottom: 0.625em;
}

.icon_set {
  font-size: 25px !important;
  margin-right: 20px;
  cursor: pointer;
}

.set_font-for_button {
  font-size: 16px !important;
}

.set_text {
  width: 470px;
  margin-top: 25px;
  text-align: center;
  font-size: 20px !important;
  font-family: Roboto;
}

.set_confirmation-submit {
  cursor: pointer;
    border-radius: 5px;
    background-color: #e5582b;
    height: 40px;
    width: 74px;
    color: white;
    border: 0px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    margin: 20px;
}

.set_confirmation-cancel {
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  width: 74px;
  border: 1px solid #f15a29;
  border-radius: 4px;
  color: #f15a29;
  background-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.table-header_table {
  width: 250px !important;
}

.table-header_file {
  width: 350px !important;
}

.icon_set_with_disable {
  font-size: 25px !important;
  margin-right: 20px;
  cursor: not-allowed;
}

.set_disable_opacity {
  opacity: 0.4;
}

.sticky-rows {
  position: sticky;
  top: calc(50px + 1px);
  z-index: 3;
  background-color: #fff;
}

.sticky-rows::-webkit-scrollbar {
  position: sticky;
  top: calc(50px + 1px);
  z-index: 3;
  background-color: #fff;
}

.sticky-cols {
  position: sticky;
  z-index: 1;
}

.sticky-cols::-webkit-scrollbar {
  position: sticky;
  z-index: 1;
}

.sticky-cols-header {
  position: sticky;
  z-index: 4;
}

.sticky-cols-header::-webkit-scrollbar {
  position: sticky;
  z-index: 4;
}

.sticky-cols-sticky {
  position: sticky;
  z-index: 2;
}

.sticky-cols-sticky::-webkit-scrollbar {
  position: sticky;
  z-index: 2;
}

.topic-camp-un-selected {
  background-color: white;
}

.Toastify__toast-body {
  font-size: 18px;
}

.delete-button {
  cursor: pointer;
  float: right;
  border-radius: 5px;
  background-color: red;
  height: 40px;
  width: 150px;
  color: white;
  border: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.disable_button_cursor {
  cursor: not-allowed;
}
.ui.disabled.button:disabled, .ui.button:disabled {
  cursor: not-allowed;
  pointer-events: inherit !important;
}

.eng_toggle {
  margin-bottom: 27px !important;
  margin-top: 20px !important;
}

.firmographic_toggle {
  margin-bottom: 27px !important;
}

.persona_toggle {
  margin-bottom: 27px !important;
  margin-top: 40px !important;
}

.only_cursor {
  cursor: pointer;
  margin-left: 0.5em;
}

.set_middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon_set_for_session {
  font-size: 23px !important;
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.deleted {
  background-color: #f9e3f0 !important;
}

.deleted-item .ui.floating.search.selection.dropdown {
  background-color: #f9e3f0;
}

.download-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  pointer-events: none;
}

.fixed-width {
  width: 100px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom; 
}

.icon-container {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.custom_font_safari {
  font-weight: 500;
}

.table_gap {
  margin-bottom: 3em;
}

.privacy_toggle {
  margin-top: 3em !important;
  margin-bottom: 1.7em !important;
  padding-left: 0.3em;
}

/* General styles for nested menus */
.nested-menu {
  background-color: #f9f9f9; 
  border-left: 2px solid #e5e5e5; 
  overflow: hidden; 
  transition: max-height 0.3s ease-in-out; 
}

.nested-menu-open {
  /* opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; */
  animation: slideDown 0.6s ease-in-out;
}

.nested-menu-closed {
  animation: slideUp 0.6s ease-in-out;
}

.nested-menu .menu-item {
  padding: 8px 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #ececec;
  transition: background-color 0.2s ease-in-out;
}

.nested-menu .menu-item:hover {
  background-color: #e8f0fe; 
  cursor: pointer;
}

.nested-menu .sidebar-icons {
  margin-right: 8px;
  color: #555;
}

.toggle-icon {
  transition: transform 0.3s ease-in-out;
}
.nested-menu-open .toggle-icon {
  transform: rotate(180deg);
}

.nested-menu-closed .toggle-icon {
  transform: rotate(0deg);
}

/* Add this new CSS for animation */
@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px; 
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    max-height: 500px; 
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.footer-save {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1000;
}

.footer-save .save-button {
  background-color: #e5582b !important;
  color: #fff !important;
  padding: 15px 30px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px; /* Slightly larger text */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.footer-save .save-button:hover {
  transform: scale(1.1); 
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #ff6a3d;
}
.report-description-table {
  border-radius: "8px";
  overflow: "hidden";
  box-shadow: "0px 4px 8px rgba(0, 0, 0, 0.1)";
}

.report-description-table-body {
  height: "50px";
  line-height: "1.2";
  padding: "0";
}

.report-description-text-area {
  width: "100%";
  height: "auto";
  padding: "8px";
  font-size: "14px";
  line-height: "1.5";
  border: "1px solid #ccc";
  border-radius: "4px";
  resize: "vertical";
  overflow: "auto";
  box-sizing: "border-box";
}


.report-description-display {
  white-space: "pre-wrap";
  word-wrap: "break-word";
  padding: "4px";
}

.report-description-icon {
  cursor: "pointer";
}
