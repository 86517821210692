.login-page-message{
	color: #3E3F42;
	font-family: Roboto;
	font-size: 28px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 32px;
	text-align: center;
	margin-top: 1em;
}

.loading{
	margin-top: 5em;
}